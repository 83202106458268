import React, { ReactNode } from 'react';
import * as ReactTabs from 'react-tabs';
import styled from 'styled-components';
import { em } from 'polished';

const { TabPanel, Tab } = ReactTabs;

interface TabProps {
  children: ReactNode;
  className?: string;
  defaultIndex?: number;
  onSelect?: (any) => void;
  forceRenderTabPanel?: boolean;
}

const StyledTabs = styled(ReactTabs.Tabs)`
  background-color: var(--secondaryDarkAlpha-color);

  .selected {
    background-color: var(--primaryDarkAlpha-color);
  }
`;

function Tabs(props: TabProps) {
  const { children, className } = props;
  return (
    <StyledTabs className={className} selectedTabClassName="selected" {...props}>
      {children}
    </StyledTabs>
  );
}

const TabList = styled(ReactTabs.TabList)`
  border-bottom: ${em(1)} solid var(--secondaryLight-color);
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;

  > * {
    font-weight: 600;
    text-align: center;
    padding: ${em(10)} !important;
    min-width: 90px;
    cursor: pointer;
    list-style: none;

    &:hover {
      background-color: var(--primaryDarkAlpha-color);
      filter: brightness(1.3);
    }

    &:focus:after {
      content: none;
    }
  }

  @media (max-width: 650px) {
    > * {
      text-align: center;
      flex-grow: 1;
      flex-basis: 0;
    }
  }
`;

export {
  Tabs,
  TabList,
  TabPanel,
  Tab,
};

import { em } from 'polished';
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { isNode } from '../utils/utils';

interface Props {
  children: ReactNode;
  className?: string;
  closeModal: () => void;
  isOpen: boolean;
}

function Modal(props: Props) {
  const { children, className, isOpen } = props;

  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  if (!isOpen && !isNode()) {
    document.body.style.overflow = 'auto';
  }

  function onAfterOpen() {
    document.body.style.overflow = 'hidden';
  }

  function onRequestClose() {
    props.closeModal();
  }

  return (
    <ReactModal
      contentLabel="Name Info"
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
    >
      {children}
    </ReactModal>
  );
}

const StyledModal = styled(Modal)`
  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(43,43,43,0.8), rgba(43,43,43,0.8));
  }

  &__content {
    border: ${em(1)} solid var(--secondaryLight-color);
    background: var(--primary-color);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0.5em;
    outline: none;
    padding: ${em(25)};
    text-align: center;
    max-height: 80vh;
  }
`;

ReactModal.setAppElement('#___gatsby');

export default StyledModal;

import ButterToast, { POS_CENTER, POS_TOP } from 'butter-toast';
import ClipboardJS from 'clipboard';
import { em } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-tabs/style/react-tabs.css';
import Top from './Top';
import Modal from '../../common/components/Modal';
import NameItem from './NameItem';
import NameCardsContainer from '../containers/NameCardsContainer';
import FormContainer from '../containers/FormContainer';
import NameSaved from './NameSaved';
import {
  Tabs, TabList, Tab, TabPanel,
} from '../../common/components/Tabbed';
import { GeneratorContext } from '../context/names-context';
import FormProvider from '../../common/context/form-provider';

const GeneratorStyled = styled.div`
  display: grid;
  grid-gap: ${em(30)};
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
        "aside header"
        "aside content";

  .Generator {
    &__form {
      background-color: var(--primaryDarkAlpha-color);
      transition: background-color 1000ms linear;
      grid-area: aside;
      padding: ${em(15)};
      width: 200px;
    }

    &__header {
      grid-area: header;
    }

    &__cards {
      grid-area: content;
    }

    &__tabcontent {
      padding: ${em(20)} ${em(5)};

      &--saved {
        max-width: ${em(265)};
        margin: 0 auto;
        padding: ${em(20)};
      }
    }
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "aside"
      "content";

    .Generator {
      &__form {
        width: 200px;
        margin: 0 auto 0 auto;
      }

      &__header {
        text-align: center;
      }
    }
  }
`;

const position = {
  horizontal: POS_CENTER,
  vertical: POS_TOP,
};

function Generator() {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(null);

  function selectName(newName) {
    setName(newName);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const clipboard = new ClipboardJS('.copy-button');
    return function cleanup() {
      clipboard.destroy();
    };
  }, []);

  return (
    <FormProvider>
      <GeneratorContext.Provider value={{ selectName }}>
        <GeneratorStyled data-testid="Generator">
          {/* toast and modal */}
          <ButterToast position={position} />
          <Modal isOpen={isOpen} closeModal={closeModal}>
            <NameItem autoFocus name={name} saveHandler={closeModal} />
          </Modal>
          {/* content */}
          <FormContainer className="Generator__form" />
          <Top
            className="Generator__header"
            title="Name Generator"
            description={
              `Generate a character name for WoW Classic with millions of unique names to pick from.
            Generate a name from all races or specific ones like Dwarf, Gnome, Human, NightElf, Orc, Tauren, Troll and Undead.`
            }
            enableThemeButton
          />
          <Tabs>
            <TabList>
              <Tab>Names</Tab>
              <Tab>Saved</Tab>
            </TabList>
            <TabPanel>
              <NameCardsContainer className="Generator__tabcontent" />
            </TabPanel>
            <TabPanel>
              <NameSaved className="Generator__tabcontent Generator__tabcontent--saved" />
            </TabPanel>
          </Tabs>
        </GeneratorStyled>
      </GeneratorContext.Provider>
    </FormProvider>
  );
}

export default Generator;

import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import Button from '../../common/components/Button';
import NameCards from '../components/NameCards';
import ErrorStyled from '../../common/components/ErrorStyled';
import { getQueryParams } from '../../common/utils/location';
import { useLocation } from '../../common/hooks';
import { useGetNamesQuery } from '../../../types/generated';

const CardContainerStyled = styled.div`
  .cards {
    justify-content: center;
  }

  .generate-button-wrapper {
    width: 100%;
    text-align: center;
  }
`;

interface Props {
  className?: string;
}

export default function NameCardsContainer(props: Props) {
  const { className } = props;
  const { location: { search } } = useLocation();
  const res = useGetNamesQuery({ variables: getQueryParams(search), fetchPolicy: 'cache-and-network' });
  const {
    data, loading, refetch, error,
  } = res;

  const onRefetch = useCallback(debounce(
    () => refetch(), 400, { leading: true, trailing: true },
  ), [search]);

  if (error) {
    return <ErrorStyled>{error.message}</ErrorStyled>;
  }

  if (data.getNames && data.getNames.length === 0) {
    return (
      <ErrorStyled>
        Oops! No names could be generated from these options.
        Click &#34;All Races&#34; for better results.
      </ErrorStyled>
    );
  }

  return (
    <CardContainerStyled className={className}>
      <NameCards names={data.getNames} className="cards" loading={loading} />
      <br />
      <div className="generate-button-wrapper">
        <Button onClick={onRefetch}>Generate</Button>
      </div>
    </CardContainerStyled>
  );
}

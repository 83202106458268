import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledCToast = styled.div`
  cursor: pointer;
  font-size: 1em;
  font-weight: 500;
  min-width: 180px;
  background-color: var(--secondaryLight-color);
  color: var(--black-color);
  text-align: center;
  padding: 10px;
  opacity: 0.96;
`;

interface Props {
  children: ReactNode;
  dismiss?: () => void;
  theme: any;
}

export default function Toast({ children, dismiss, theme }: Props) {
  return (
    <StyledCToast theme={theme} onClick={dismiss}>{children}</StyledCToast>
  );
}

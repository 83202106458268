const lang = 'en-GB';
const voiceIndex = 0;
const voiceName = 'Google UK English Male';

export function isSpeechSupported() {
  return typeof window !== 'undefined' && 'speechSynthesis' in window;
}

function getVoices(): Promise<SpeechSynthesisVoice[]> {
  return new Promise((resolve) => {
    let voices = speechSynthesis.getVoices();
    if (voices.length) {
      resolve(voices);
      return;
    }
    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices();
      resolve(voices);
    };
  });
}

async function chooseVoice() {
  const voices = await getVoices();
  const oneVoice = voices.filter(voice => voice.name === voiceName);
  const langVoices = voices.filter(voice => voice.lang === lang);
  return [...oneVoice, ...langVoices][voiceIndex];
}

export async function sayText(text) {
  speechSynthesis.cancel();
  const message = new SpeechSynthesisUtterance(text);
  message.pitch = 0.9;
  message.rate = 0.8;
  message.voice = await chooseVoice();
  speechSynthesis.speak(message);
}

import classNames from 'classnames';
import { em } from 'polished';
import React from 'react';
import styled from 'styled-components';

const CardStyled = styled.div`
  .name {
    cursor: pointer;
    padding: ${em(10)} ${em(5)};
    color: white;
    background-color: var(--primary-color);
    font-family: 'Bitter', serif;
    font-size: 1em;
    border: ${em(1)} solid var(--secondaryLight-color);
    border-radius: 0.5em;
    min-width: ${em(130)};
    text-align: center;

    &--saved {
      background-color: var(--secondaryLight-color);
      color: var(--black-color);
    }
  }

  @media (hover: hover) {
    .name {

      &:not(.name--saved):hover {
        background-color: var(--secondaryLightAlpha-color);
      }

      &--saved:hover {
        background-color: var(--secondaryLight-color);
        filter: brightness(1.2);
      }
    }
  }
`;

interface Props {
  name: string;
  saved: boolean;
  onClick?(name): void;
}

export default function NameCard(props: Props) {
  const { name, saved } = props;

  function onClick() {
    props.onClick(name);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      onClick();
    }
  }

  const nameStyle = classNames('name', {
    'name--saved': saved,
  });

  return (
    <CardStyled tabIndex={0} onClick={onClick} onKeyPress={handleKeyPress}>
      <div className={nameStyle}>{name}</div>
    </CardStyled>
  );
}

import classNames from 'classnames';
import { em } from 'polished';
import React, { useContext, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../common/components/Button';
import ButtonImage from '../../common/components/ButtonImage';
import InputText from '../../common/components/InputText';
import InputRange from '../../common/components/InputRange';
import { getRaceImage, upperCaseDashes } from '../../common/utils/utils';
import { FormContext } from '../../common/context/form-context';
import { rangeProps, textProps } from '../../common/utils/inputs';
import { useField } from '../../common/hooks';
import { capitalize } from '../../../shared/utils';

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  user-select: none;

  > * {
    margin-bottom: ${em(20)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  .races {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${em(12)};
    justify-items: center;
  }

  .race {
    &__all {
      background-color: var(--primary-color);
      grid-column: 1 / span 3;
      margin-bottom: ${em(5)};
    }

    &--inactive {
      filter: grayscale(0.9) opacity(0.5);

      &:hover {
        filter: grayscale(0.3) opacity(0.8);
      }
    }
  }
`;

interface Props {
  className: string;
  races: string[];
}

export default function Form(props: Props) {
  const { className, races } = props;
  const { dispatch } = useContext(FormContext);
  const maxLength = useField<number>('maxLength', 6);
  const [minLength, setMinLength] = useField<number>('minLength', 6);
  const startLetters = useField<string>('startLetters', '');
  const [race, setRace] = useField<string>('race', null);

  useEffect(() => {
    setMinLength(maxLength[0]);
  }, [maxLength[0]]);

  const onRaceClick = (newRace = null) => {
    setRace(newRace);
    dispatch({ type: 'update', query: { race: newRace } });
  };
  const onRaceClickNull = onRaceClick.bind(this, null);

  const onSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: 'trigger' });
  }, [race, minLength, maxLength[0], startLetters[0]]);

  function getButtonStyle(extraClass = '', selectedRace = null) {
    return classNames(`race ${extraClass}`, { 'race--inactive': (race && race !== selectedRace) });
  }

  return (
    <FormStyled className={className} onChange={onSubmit} onSubmit={onSubmit}>
      <div className="races">
        <Button className={getButtonStyle('race__all')} key="AllRaces" onClick={onRaceClickNull}>All Races</Button>
        {
          races.map((item, index) => {
            const gender = index % 2 === 0 ? 'male' : 'female';
            const imageUrl = getRaceImage(upperCaseDashes(item).toLocaleLowerCase(), gender);
            return (
              <ButtonImage
                className={getButtonStyle('', item)}
                key={item}
                image={imageUrl}
                name={item}
                title={capitalize(item)}
                onClick={onRaceClick}
              />
            );
          })
        }
      </div>
      <InputRange
        name="maxLength"
        minLength={2}
        maxLength={12}
        {...rangeProps(maxLength)}
      />
      <InputText
        name="startLetters"
        maxLength={11}
        label="Prefix (Only A to Z)"
        pattern="[A-z]{1,11}"
        {...textProps(startLetters)}
      />
    </FormStyled>
  );
}

import { em } from 'polished';
import React, { useContext } from 'react';
import styled from 'styled-components';
import NameCard from './NameCard';
import { noop } from '../../common/utils/utils';
import { GeneratorContext } from '../context/names-context';
import { GlobalContext } from '../../common/context/global-context';
import { GetNamesQuery } from '../../../types/generated';

const CardsStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin: ${em(10)};
  }
`;

interface Props {
  className: string;
  loading: boolean;
  names: GetNamesQuery['getNames'];
}

export default function NameCards(props: Props) {
  const { className, loading, names } = props;
  const { selectName } = useContext(GeneratorContext);
  const { state } = useContext(GlobalContext);

  const namesArr = loading ? Array(12).fill({ name: '...' }) : names;
  return (
    <CardsStyled className={className}>
      {
        namesArr.map((item, index) => (
          <NameCard
            name={item.name}
            key={`card${index * 2}`}
            onClick={loading ? noop : selectName}
            saved={state.has(item.name)}
          />
        ))
      }
    </CardsStyled>
  );
}

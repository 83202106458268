import React from 'react';
import Generator from '../client/names/components/Generator';
import RelatedTools from '../client/common/components/RelatedTools';
import Layout from '../client/common/layout/Layout';
import SEO from '../client/common/layout/SEO';

const NameGenerator = ({ location }) => (
  <Layout mainClass="page-container page-padding">
    <SEO
      title="WoW Name Generator"
      description="Create a unique character name for WoW classic from all races or specific ones like Dwarf, Gnome, Human, NightElf, Orc, Tauren, Troll and Undead."
      keywords={['classic', 'wow', 'world of warcraft', 'wow classic', 'name generator', 'generate name']}
      location={location}
      hasSuffix
    />
    <Generator />
    <br />
    <hr />
    <RelatedTools />
  </Layout>
);

export default NameGenerator;

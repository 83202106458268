import { em } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from '../hooks';

const LabelStyled = styled.label`
  color: white;
`;

const HeaderStyled = styled.span`
  display: block;
  padding-bottom: ${em(3)};
`;

const InputStyled = styled.input`
  color: black;
  background-color: var(--primaryLight-color);
  transition: background-color, border 1000ms linear;
  border: 1.9px solid ${props => props.theme.secondaryDarkAlpha};
  box-sizing: border-box;
  font-size: 1em;
  padding: ${em(8)};
  width: 100%;

  :focus {
    outline: ${em(1)} solid var(--primary-color);
  }
`;

interface Props {
  autoFocus?: boolean;
  value: string;
  name: string;
  label: string;
  pattern: string;
  maxLength: number;
  onChange?: (e?) => void;
  onKeyPress?: (e?) => void;
}

export default function InputText(props: Props) {
  const {
    autoFocus, value, label, maxLength, name, pattern, onChange, onKeyPress,
  } = props;
  const [innerValue, setInnerValue] = useState(value);
  const debounced = useDebounce((newValue) => {
    onChange({ target: { value: newValue } });
  });

  const handleChange = (e) => {
    setInnerValue(e.target.value);
  };

  useEffect(() => {
    debounced.current(innerValue);
  }, [innerValue]);

  const regex = new RegExp(pattern);

  const handleKeyPress = (e) => {
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  return (
    <LabelStyled htmlFor={name}>
      <HeaderStyled>{label}</HeaderStyled>
      <InputStyled
        type="text"
        name={name}
        autoFocus={autoFocus}
        value={innerValue}
        pattern={pattern}
        maxLength={maxLength}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        autoComplete="off"
      />
    </LabelStyled>
  );
}

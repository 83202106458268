import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Form from '../components/Form';

interface Props {
  className: string;
}

export default function FormContainer({ className }: Props) {
  const data = useStaticQuery(graphql`
    query FormQuery {
      site {
        siteMetadata {
          names {
            races 
          }
        }
      }
    }
  `);

  const { races } = data.site.siteMetadata.names;
  return (
    <Form className={className} races={races} />
  );
}

import {
  faCopy, faMinus, faPlay, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButterToast from 'butter-toast';
import ClipboardJS from 'clipboard';
import { em } from 'polished';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { isSpeechSupported, sayText } from '../../common/utils/speech';
import { isNode } from '../../common/utils/utils';
import Button from '../../common/components/Button';
import Toast from '../../common/components/Toast';
import { GlobalContext } from '../../common/context/global-context';

const StyledCName = styled.div`
  display: flex;
  flex-direction: column;

  .buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${em(14)};
    font-size: ${em(16)};

    button {
      padding: ${em(8)} ${em(15)};
    }
  }

  h1 {
    font-family: 'Bitter', serif;
    font-size: 1.5em;
    margin-bottom: ${em(15)};
    flex-shrink: 0;
  }

  ${(props: Props) => (!props.slim ? '' : css`
    align-items: center;
    flex-direction: row;
    font-size: ${em(12)};

    h1 {
      flex-grow: 1;
      margin-bottom: 0;
      margin-right: ${em(15)};
      text-align: left;
    }
  `)}
`;

interface Props {
  autoFocus?: boolean;
  className?: string;
  name: string;
  saveHandler?: () => void;
  slim?: boolean;
}

export default function NameItem(props: Props) {
  const { autoFocus, name, saveHandler } = props;
  const { state, dispatch, theme } = useContext(GlobalContext);

  function onCopyButton(e) {
    e.target.focus();
    ButterToast.raise({
      content: ({ dismiss }) => (
        <Toast theme={theme} dismiss={dismiss}>Copied to clipboard!</Toast>
      ),
      timeout: 2000,
    });
  }

  async function onPlayButton() {
    await sayText(name);
  }

  function onSaveButton() {
    dispatch({ name, type: 'toggle' });
    const text = state.has(name) ? 'Removed name!' : 'Added name!';
    ButterToast.raise({
      content: ({ dismiss }) => (
        <Toast theme={theme} dismiss={dismiss}>{text}</Toast>
      ),
      timeout: 2000,
    });
    if (saveHandler) {
      saveHandler();
    }
  }

  return (
    <StyledCName {...props}>
      <h1>{name}</h1>
      <div className="buttons">
        {
          (isNode() || ClipboardJS.isSupported()) && (
            <Button
              autoFocus={autoFocus}
              className="copy-button"
              copyText={name}
              onClick={onCopyButton}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          )
        }
        <Button onClick={onSaveButton}>
          <FontAwesomeIcon icon={state.has(name) ? faMinus : faPlus} />
        </Button>
        {
          isSpeechSupported() && (
            <Button onClick={onPlayButton}>
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          )
        }
      </div>
    </StyledCName>
  );
}

import { em } from 'polished';
import React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
  position: relative;
  background-color: black;
  cursor: pointer;
  font-family: 'Bitter', serif;
  font-size: 0.78em;
  width: 57px;
  height: 66px;
  text-align: center;
  box-shadow: 5px 5px 15px var(--primaryAlpha-color);
  border: ${em(4)} outset var(--primaryDark-color);
  transition: box-shadow, border 1000ms linear;
  touch-action: manipulation;

  &:hover {
    filter: brightness(1.2);
  }

  img {
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  span {
    background-image: linear-gradient(to bottom, transparent, black);
    color: var(--gold-color);
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

interface Props {
  className?: string;
  image: string;
  name: string;
  title: string;
  onClick: (name: string) => void;
}

export default function ButtonImage(props: Props) {
  const {
    className, image, name, title,
  } = props;

  function onClick() {
    return props.onClick(props.name);
  }

  return (
    <ButtonStyled className={className} onClick={onClick} name={name}>
      <img alt={title} src={image} />
      <span>{title}</span>
    </ButtonStyled>
  );
}

import React, { useEffect, useState } from 'react';
import { em } from 'polished';
import styled from 'styled-components';
import { useDebounce } from '../hooks';

const height = 25;
const thumbWidth = 25;

const LabelStyled = styled.label`

`;

const HeaderStyled = styled.span`
  display: block;
  padding-bottom: ${em(3)};

  > span {
    font-weight: 600;
  }
`;

const InputStyled = styled.input`
  height: ${height}px;
  width: 100%;
  -webkit-appearance: none;
  margin: 0 0;

  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      background: var(--primaryLightAlpha-color);
    }

    &::-ms-fill-lower {
      background: ${(props: any) => props.theme.primaryLightAlpha};
    }

    &::-ms-fill-upper {
      background: ${(props: any) => props.theme.primaryLightAlpha};
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    box-shadow: none;
    background: var(--primaryLight-color);
    border-radius: 0;
    border: 0;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 1.9px solid var(--secondaryDarkAlpha-color);
    height: ${height}px;
    width: ${thumbWidth}px;
    border-radius: 0;
    background: var(--primaryDark-color);
    transition: background 1000ms linear;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0;
  }

  &::-moz-range-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    box-shadow: none;
    background: var(--primaryLight-color);
    border-radius: 0;
    border: 0;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: 1.9px solid var(--secondaryDarkAlpha-color);
    height: ${height}px;
    width: ${thumbWidth}px;
    border-radius: 0;
    background: var(--primaryDark-color);
    transition: background 1000ms linear;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: ${height}px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: ${(props: any) => props.theme.primaryLightAlpha};
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &::-ms-fill-upper {
    background: ${(props: any) => props.theme.primaryLight};
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &::-ms-thumb {
    box-shadow: none;
    border: 1.9px solid ${(props: any) => props.theme.secondaryDarkAlpha};
    width: ${thumbWidth}px;
    border-radius: 0;
    background: ${(props: any) => props.theme.primaryDark};
    transition: background 1000ms linear;
    cursor: pointer;
    height: ${height}px;
  }
`;

interface Props {
  minLength: number;
  maxLength: number;
  name: string;
  value: string;
  onChange: (event) => void;
}

export default function InputRange(props: Props) {
  const {
    maxLength, minLength, name, onChange, value,
  } = props;
  const [innerValue, setInnerValue] = useState(value);
  const debounced = useDebounce((newValue) => {
    onChange({ target: { value: newValue } });
  });

  const handleChange = (e) => {
    setInnerValue(e.target.value);
  };

  useEffect(() => {
    debounced.current(innerValue);
  }, [innerValue]);

  return (
    <LabelStyled htmlFor={name}>
      <HeaderStyled>Name Length: <span>{innerValue}</span></HeaderStyled>
      <InputStyled
        type="range"
        value={innerValue}
        min={minLength}
        max={maxLength}
        onChange={handleChange}
        name={name}
      />
    </LabelStyled>
  );
}

import { em } from 'polished';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import * as constants from '../utils/constants';
import storage from '../../common/utils/storage';
import { hashText, sortNames } from '../../common/utils/utils';
import InputText from '../../common/components/InputText';
import NameItem from './NameItem';
import { capitalize } from '../../../shared/utils';
import { GlobalContext, themes } from '../../common/context/global-context';

const StyledCSaved = styled.div`
  min-width: ${em(250)};

  label {
    display: block;
    text-align: left;
    padding-bottom: ${em(20)};
    margin-bottom: ${em(20)};
    border-bottom: ${em(1)} solid var(--secondaryLight-color);
  }

  .saved {
    > * {
      margin-bottom: ${em(15)};
    }
  }
`;

interface Props {
  className?: string;
}

export default function NameSaved(props: Props) {
  const { className } = props;
  const { state, dispatch, setTheme } = useContext(GlobalContext);
  const [newName, setNewName] = useState<string>('');

  function onChange(e) {
    setNewName(e.target.value);
  }

  const onKeyPress = async (e) => {
    const name = capitalize(newName);

    if (e.key === 'Enter' && name && name.length > 1) {
      // check for horde theme code
      hashText(name.toLocaleLowerCase()).then((hash) => {
        if (hash === constants.HORDE_HASH) {
          setTheme(themes.horde);
          storage.set('horde', hash);
          dispatch({ name, type: 'delete' });
        }
      });

      dispatch({ name, type: 'add' });
      setNewName('');
    }
  };

  return (
    <StyledCSaved className={className}>
      <InputText
        name="new-name"
        label="Add name"
        onKeyPress={onKeyPress}
        onChange={onChange}
        value={newName}
        pattern="[A-z]{1,11}"
        maxLength={12}
      />
      <div className="saved">
        {
          state.size === 0 ? <strong>No saved names.</strong>
            : Array.from(state).sort(sortNames).map(name => (
              <NameItem key={name} name={name} slim />
            ))
        }
      </div>
    </StyledCSaved>
  );
}
